<script>


/**
 * Landlord GST Setup component
 */


//  import {
//   required,
// } from "vuelidate/lib/validators";

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'


import {
    required,
} from "vuelidate/lib/validators";
import {getPMApi} from '@/api/pm'


export default {

    emits: ['confirm', 'cancel', 'onDelete'],

    props: {
        property : {
            type: Object,
            default: () => {
                
            }
        },
        owner: {
            type: Object,
            default: () => {
                return {
                    payment_type: '',
                    name: '',
                    institution: '',
                    transit    : '',
                    account    : '',
                    bto_id     : ''
                }

            }
        },
        landlord_list: {
            type: Array,
            default: () => []
        },
    },
    components: {
        VueBootstrapTypeahead
    },

    validations() {
        if (this.owner.payment_type == 'EFT') {
            return {
                owner: {
                    institution   : { required },
                    transit       : { required },
                    account       : { required },
                }
            }
        } else {
            return {
                owner: {
                    institution: {},
                    transit: {},
                    account: {},
                }
            }
        }
    },


    data() {

        return {
            submitted: false,
            payment_type_list: [
                { value: 'EFT', label: 'EFT', selected: false },
                { value: 'CHEQUE', label: 'Cheque', selected: false },
            ],
            payment_type_choice: {},

            temp_native_id : -12001,
            local_owner_list : []
            
        }

    },
    computed: {

    },
    methods: {
        formSubmit() {
            this.$v.$touch()
            if (this.$v.$invalid == false) {
                this.onSubmitBto()
            }

        },

        onOwnerSelect(evt) {

            this.owner.name                = evt.name
            this.owner.payment_type        = evt.payment_type
            this.owner.institution         = evt.institution
            this.owner.transit             = evt.transit
            this.owner.account             = evt.account
            this.owner.bto_id              = evt.id
        },



        onAddNewBTO() {
            let new_bto = {
                name             : 'New BTO '+ (this.local_owner_list.length +1),
                payment_amount   : '',
                payment_type     : 'CHEQUE',
                bank_inst        : '',
                bank_transit     : '',
                bank_account     : '',
                bto_id           : '',
            }
            this.local_owner_list.push(new_bto)

            this.onOwnerSelect(new_bto)
        },  

        onSubmitBto() {
            let data = {
                pm_id                    : this.property.property_id,
                bto_id                   : this.owner.bto_id,
                payment_type             : this.owner.payment_type,
                institution              : this.owner.institution,
                transit                  : this.owner.transit,
                account                  : this.owner.account,
                bto_name                 : this.owner.name   
            }

            getPMApi().edit_rta_bto_person(data).then((res)=>{
                if (res.errCode == 0) {
                    this.$alertify.message("Update BTO Person  Successfully");
                
                    this.$bvModal.hide('bto_person_edit_dialog')
                } else {
                    this.$alertify.error("Update BTO Person   failed:" + res.errCode);
                }
            })
        },

      
    },

    created() {

    },

    mounted() {
        this.local_owner_list.push(...this.landlord_list)
        this.local_owner_list.map(e => e.name = e.landlord_name +(e.is_corporatuin == 1? '' : (e.last_name ?  e.last_name : '')))
        this.owner.payment_type  = this.property.l_payment
        this.owner.name          = this.property.owner_name
        this.owner.institution   = this.property.l_institution
        this.owner.transit       = this.property.l_transfer
        this.owner.account       = this.property.l_account_number
        this.owner.bto_id        = this.property.bto_id

        this.$refs['owner_name_ref'].setValue(this.owner.name)

    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom02">BTO Name</label>
                            <vue-bootstrap-typeahead v-model="owner.name" :data="local_owner_list" :ref="'owner_name_ref'" 
                                :serializer="s => s.name" :foramterDisplay="s => s.name" @hit="onOwnerSelect($event)"
                                autocomplete="off" maxlength="22" />

                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="validationCustom01">PAD Payment</label>
                            <b-form-select id="tenant_payment_type" v-model="owner.payment_type" class="form-control" :options="[{value: 'EFT', text: 'EFT'},{value: 'CHEQUE', text: 'Cheque'}]">
                            </b-form-select>
                        </div>
                    </div>

                </div>
                <!-- end row-->

                <div class="row mb-3">
                    <div class="col-md-4">
                        <div class="mb-md-0">
                            <label for="contact-info-website-input" class="form-label">Financial Institution</label>
                            <input type="text" class="form-control" v-model="owner.institution"
                                placeholder="Enter Bank  Institution Number" :disabled="owner.payment_type != 'EFT'"  :class="{'is-invalid' : $v.owner.institution.$error}"/>
                            <div v-if="$v.owner.institution.$error" class="invalid-feedback">
                                <span v-if="!$v.owner.institution.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-md-0">
                            <label for="contact-info-location-input" class="form-label">Financial Transit</label>
                            <input type="text" class="form-control" v-model="owner.transit"
                                placeholder="Enter Bank Transit Number" :disabled="owner.payment_type != 'EFT'" :class="{'is-invalid' : $v.owner.transit.$error}" />
                            <div v-if="$v.owner.transit.$error" class="invalid-feedback">
                                <span v-if="!$v.owner.transit.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-md-0">
                            <label for="contact-info-location-input" class="form-label">Financial Account</label>
                            <input type="text" class="form-control" v-model="owner.account"
                                placeholder="Enter Bank Account" :disabled="owner.payment_type != 'EFT'" :class="{'is-invalid' : $v.owner.account.$error}" />
                            <div v-if="$v.owner.account.$error" class="invalid-feedback">
                                <span v-if="!$v.owner.account.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-2">

                    </div>
                    <div class="col-md-8">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Submit</b-button>
                            <b-button variant="primary"  type="button" @click="onAddNewBTO">Add New Person</b-button>
                        </div>
                    </div>
                    <div class="col-md-2">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>